import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Modal from 'react-modal';
import Advertisement from './Advertisement';
import styles from './AdForm.module.css';
import debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaMapMarkerAlt, FaRegCopy, FaCheck, FaInfoCircle } from 'react-icons/fa';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { CircularProgress, Box, Typography, Stepper, Step, StepLabel, IconButton } from '@mui/material';
import { Info as InfoIcon, Close as CloseIcon } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';

Modal.setAppElement('#root');

function AdForm({ updateCredits, images, mapScreenshot, setMapScreenshot }) {
  const { t, i18n } = useTranslation();
  const { user, isAuthenticated } = useAuth0();

  const [addressError, setAddressError] = useState(false);
  const [amenities, setAmenities] = useState({});
  const [amenitiesFetched, setAmenitiesFetched] = useState(false);
  const [includeAmenities, setIncludeAmenities] = useState(true);
  const [adStyle, setAdStyle] = useState('automatic');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [area, setArea] = useState('');
  const [rooms, setRooms] = useState('');
  const [availableFrom, setAvailableFrom] = useState('');
  const [priceNet, setPriceNet] = useState('');
  const [priceGross, setPriceGross] = useState('');
  const [extraCosts, setExtraCosts] = useState('');
  const [floor, setFloor] = useState('');
  const [adType, setAdType] = useState('Miete');
  const [generatedAd, setGeneratedAd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [additionalInfoModalIsOpen, setAdditionalInfoModalIsOpen] = useState(false);
  const [viewingAppointmentsModalIsOpen, setViewingAppointmentsModalIsOpen] = useState(false);
  const [address, setAddress] = useState('');
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [showAddressInAd, setShowAddressInAd] = useState(true);
  const [rentalUse, setRentalUse] = useState('');
  const [priceOnRequest, setPriceOnRequest] = useState(false);
  const [credits, setCredits] = useState(0);
  const [adLanguage, setAdLanguage] = useState(i18n.language);
  const [appointments, setAppointments] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [missingFields, setMissingFields] = useState([]);
  const [copied, setCopied] = useState(false);
  const [generatingAdStep, setGeneratingAdStep] = useState(0);

  const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5001';
  const adContentRef = useRef();
  const suggestionsRef = useRef();
  const uniqueAmenities = Array.from(new Set(Object.keys(amenities)));

  const steps = [
    t('Initializing'),
    t('Fetching Nearby Amenities'),
    t('Generating Advertisement'),
    t('Completed'),
  ];

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(`${apiUrl}/get-credits?user_id=${user.sub}`)
        .then((response) => {
          setCredits(response.data.credits);
        })
        .catch((error) => {
          console.error('Error fetching credits', error);
        });
    }
  }, [isAuthenticated, user, apiUrl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setAddressSuggestions([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const openAdditionalInfoModal = () => setAdditionalInfoModalIsOpen(true);
  const closeAdditionalInfoModal = () => setAdditionalInfoModalIsOpen(false);
  const openViewingAppointmentsModal = () => setViewingAppointmentsModalIsOpen(true);
  const closeViewingAppointmentsModal = () => setViewingAppointmentsModalIsOpen(false);

  const fetchAddressSuggestions = async (query) => {
    try {
      const response = await axios.get(`${apiUrl}/address-suggestions?query=${encodeURIComponent(query)}`);
      setAddressSuggestions(response.data);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const debouncedFetchAddressSuggestions = useCallback(debounce(fetchAddressSuggestions, 300), [apiUrl]);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    setAddressError(false); // Reset error state on new input
    // Clear map screenshot if the address is cleared
    if (newAddress.trim() === '') {
      setMapScreenshot(null);
      setAddressSuggestions([]);
    } else if (newAddress.length >= 5) {
      debouncedFetchAddressSuggestions(newAddress);
    } else {
      setAddressSuggestions([]);
    }
  };

  const handleAddressBlur = () => {
    if (address) {
      debouncedFetchMapScreenshot(address);
    }
  };

  const fetchMapScreenshot = async (address) => {
    try {
      const response = await axios.post(
        `${apiUrl}/get_map_screenshot`,
        { address },
        { headers: { 'Content-Type': 'application/json' } }
      );
      console.log('Map Screenshot Response:', response.data); // Debugging line
      if (response.data.map_screenshot) {
        setMapScreenshot(response.data.map_screenshot);
        setAddressError(false);
      } else {
        handleAddressError();
      }
    } catch (error) {
      console.error('Error fetching map screenshot:', error);
      handleAddressError();
    }
  };

  const handleAddressError = () => {
    setAddressError(true);
    setAddress('');
    setMapScreenshot(null);
    setAddressSuggestions([]);
  };

  const debouncedFetchMapScreenshot = useCallback(debounce(fetchMapScreenshot, 1000), [apiUrl]);

  const handleAddAppointment = () => {
    if (appointmentDate) {
      const formattedDate = appointmentDate
        .toLocaleString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
        .replace(',', '');

      setAppointments([...appointments, formattedDate]);
      setAppointmentDate(null);
    }
  };

  const handleRemoveAppointment = (index) => {
    const newAppointments = appointments.filter((_, i) => i !== index);
    setAppointments(newAppointments);
  };

  const validateForm = () => {
    const requiredFields = ['area', 'address', 'rooms'];
    const missingFields = [];

    if (!area) missingFields.push(t('Area (sqm)'));
    if (!address) missingFields.push(t('Address'));
    if (!rooms || Number(rooms) < 1) missingFields.push(t('Number of rooms'));

    setMissingFields(missingFields);
    return missingFields.length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      alert(t('Please fill in all required fields: ') + missingFields.join(', '));
      return;
    }

    if (credits <= 0) {
      alert(t('Not enough credits to generate an ad.'));
      return;
    }

    setLoading(true);
    setAmenitiesFetched(false);
    setGeneratingAdStep(1);

    setGeneratedAd(null);

    const translatedRentalUse = rentalUse || t('Apartment');
    const translatedAdType = adType || t('Rent');

    const formData = {
      adStyle,
      additionalInfo: additionalInfo || undefined,
      area: area || undefined,
      rooms: rooms || undefined,
      availableFrom: availableFrom || t('Available from now'),
      priceNet: priceOnRequest ? t('Price on request') : priceNet || undefined,
      priceGross: priceGross || undefined,
      extraCosts: extraCosts || undefined,
      floor: floor || undefined,
      adType: translatedAdType,
      address: address || undefined,
      rentalUse: translatedRentalUse,
      showAddressInAd,
      language: adLanguage,
      appointments: appointments.length > 0 ? appointments : undefined,
    };

    try {
      let amenitiesData = {};
      if (includeAmenities) {
        const amenitiesResponse = await axios.post(
          `${apiUrl}/get_nearby_amenities`,
          { address },
          { headers: { 'Content-Type': 'application/json' } }
        );

        if (amenitiesResponse.data.amenities) {
          amenitiesData = amenitiesResponse.data.amenities;
          setAmenities(amenitiesData);  // Update state to render amenities with distances
          setAmenitiesFetched(true);
          setGeneratingAdStep(2);
        }
      }

      const imagesBase64 = await Promise.all(images.slice(0, 9).map((file) => encodeImage(file)));

      const payload = {
        ...formData,
        images: imagesBase64,
        screenshot: mapScreenshot,
        amenities_data: amenitiesData,
        user_id: user.sub,  // Adding user ID for the request
      };

      const response = await axios.post(`${apiUrl}/upload`, payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      setGeneratedAd(response.data.ad_content);
      setCredits(response.data.credits); // Correct field name
      updateCredits(response.data.credits); // Correct field name
      setGeneratingAdStep(3);
    } catch (error) {
      console.error('Error submitting ad:', error.response ? error.response.data : error.message);
      alert(t('Failed to generate ad. Please try again.'));
      setGeneratingAdStep(0);
    } finally {
      setLoading(false);
    }
  };

  const encodeImage = (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(imageFile);
    });
  };

  const copyToClipboard = (event) => {
    event.preventDefault();
    if (adContentRef.current) {
      const range = document.createRange();
      range.selectNode(adContentRef.current);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand('copy');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (err) {
        console.error('Error copying to clipboard: ', err);
        alert(t('Failed to copy ad to clipboard.'));
      }
      window.getSelection().removeAllRanges();
    }
  };

  const renderLabel = (labelText, isRequired) => (
    <label className={isRequired ? styles.requiredLabel : ''}>{labelText}</label>
  );

  const renderAmenitiesWithDistance = () => {
    const amenityIcons = {
      preschool: "😊",
      primary_school: "😊",
      secondary_school: "😊",
      university: "🎓",
      library: "📚",
      park: "🌳",
      playground: "🏞️",
      restaurant: "🍽️",
      bar: "🍻",
      cafe: "☕",
      bakery: "🥐",
      shopping_mall: "🛍️",
      supermarket: "🛒",
      convenience_store: "🏪",
      hospital: "🏥",
      pharmacy: "💊",
      spa: "💆",
      train_station: "🚉",
      subway_station: "🚇",
      bus_station: "🚌",
      bus_stop: "🚏",
      taxi_stand: "🚖",
      airport: "✈️",
      gym: "🏋️",
      fitness_center: "🏋️‍♀️",
      ski_resort: "⛷️",
      stadium: "🏟️",
      swimming_pool: "🏊",
      post_office: "📮",
    };

    return (
      <div className={styles.amenitiesSection}>
        <h3>{t('Nearby Amenities')}</h3>
        {uniqueAmenities.length === 0 ? (
          <p>{t('No amenities found nearby.')}</p>
        ) : (
          uniqueAmenities.map((type) => {
            const amenityValues = amenities[type];
            return (
              <div key={type} className={styles.amenityType}>
                <h4>{t(type)} {amenityIcons[type]}</h4>
                <ul className={styles.amenitiesList}>
                  {amenityValues.map((amenity, index) => (
                    <li key={index} className={styles.amenityItem}>
                      <FaMapMarkerAlt className={styles.amenityIcon} />
                      <span className={styles.amenityName}>{amenity.name}</span>
                      <span className={styles.amenityDistance}>
                        {amenity.distance ? `${amenity.distance.toFixed(1)} km` : t('Distance not available')}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className={styles.adForm}>
      {/* Ad Style Section */}
      <div className={styles.formSection}>
        <h2>
          {t('Ad Style')}
          <Tooltip title={t('Learn more about Ad Styles')}>
            <IconButton className={styles.infoButton} onClick={openModal}>
              <FaInfoCircle />
            </IconButton>
          </Tooltip>
        </h2>
        <div className={styles.formGroup}>
          {renderLabel(t('Ad Style'), true)}
          <select
            id="ad-style"
            value={adStyle}
            onChange={(e) => setAdStyle(e.target.value)}
            required
            disabled={loading}
          >
            <option value="">{t('Select one')}</option>
            <option value="automatic">{t('Automatic')}</option>
            <option value="emotional">{t('Emotional')}</option>
            <option value="narrative">{t('Narrative')}</option>
            <option value="factual">{t('Factual')}</option>
            <option value="minimalist">{t('Minimalist')}</option>
          </select>
        </div>
      </div>

      {/* Checkbox for Including Amenities */}
      <div className={styles.checkboxGroup}>
        <FormControlLabel
          control={
            <Checkbox
              checked={includeAmenities}
              onChange={(e) => setIncludeAmenities(e.target.checked)}
              name="includeAmenities"
              color="primary"
              disabled={loading}
            />
          }
          label={t('Include Nearby Amenities')}
        />
      </div>

      {/* Additional Info Section */}
      <div className={styles.formSection}>
        <h2>{t('Additional Info')}</h2>
        <div className={styles.formGroup}>
          {renderLabel(t('Area (sqm)'), true)}
          <input
            type="number"
            id="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            placeholder={t('Enter area in sqm')}
            required
            disabled={loading}
          />
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Number of rooms'), true)}
          <input
            type="number"
            id="rooms"
            value={rooms}
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || Number(value) >= 1) {
                setRooms(value);
              }
            }}
            placeholder={t('Enter number of rooms')}
            required
            min="1"
            step="1"
            disabled={loading}
          />
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Available From'), false)}
          <input
            type="text"
            id="available-from"
            value={availableFrom}
            onChange={(e) => setAvailableFrom(e.target.value)}
            placeholder={t('Enter Date or leave blank if available from now')}
            disabled={loading}
          />
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Type'), false)}
          <select
            id="ad-type"
            value={adType}
            onChange={(e) => setAdType(e.target.value)}
            disabled={loading}
          >
            <option value="Miete">{t('Rent')}</option>
            <option value="Kaufen">{t('Buy')}</option>
          </select>
        </div>
        {adType === 'Miete' ? (
          <>
            <div className={styles.formGroup}>
              {renderLabel(t('Rent Price Net (CHF)'), false)}
              <input
                type="number"
                id="price-net"
                value={priceNet}
                onChange={(e) => setPriceNet(e.target.value)}
                placeholder={t('Net rent price in CHF')}
                disabled={priceOnRequest || loading}
              />
            </div>
            <div className={styles.formGroup}>
              {renderLabel(t('Rent Price Gross (CHF)'), false)}
              <input
                type="number"
                id="price-gross"
                value={priceGross}
                onChange={(e) => setPriceGross(e.target.value)}
                placeholder={t('Gross rent price in CHF')}
                disabled={priceOnRequest || loading}
              />
            </div>
            <div className={styles.formGroup}>
              {renderLabel(t('Extra Costs (CHF)'), false)}
              <input
                type="number"
                id="extra-costs"
                value={extraCosts}
                onChange={(e) => setExtraCosts(e.target.value)}
                placeholder={t('Extra costs in CHF')}
                disabled={priceOnRequest || loading}
              />
            </div>
          </>
        ) : (
          <div className={styles.formGroup}>
            {renderLabel(t('Buy Price (CHF)'), false)}
            <input
              type="number"
              id="price"
              value={priceNet}
              onChange={(e) => setPriceNet(e.target.value)}
              placeholder={t('Buy price in CHF')}
              disabled={priceOnRequest || loading}
            />
          </div>
        )}
        <div className={styles.checkboxGroup}>
          <FormControlLabel
            control={
              <Checkbox
                checked={priceOnRequest}
                onChange={(e) => setPriceOnRequest(e.target.checked)}
                name="priceOnRequest"
                color="primary"
                disabled={loading}
              />
            }
            label={t('Price on Request')}
          />
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Floor'), false)}
          <input
            type="number"
            id="floor"
            value={floor}
            onChange={(e) => setFloor(e.target.value)}
            placeholder={t('Floor number')}
            disabled={loading}
          />
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Address'), true)}
          <input
            type="text"
            id="address"
            value={address}
            onChange={handleAddressChange}
            onBlur={handleAddressBlur}
            placeholder={addressError ? t('Address not found, try again.') : t('Enter address')}
            required
            disabled={loading}
            className={`${addressError ? styles.errorInput : ''} ${styles.addressInput}`}
          />
          {addressSuggestions.length > 0 && (
            <ul className={styles.suggestions} ref={suggestionsRef}>
              {addressSuggestions.map((suggestion) => (
                <li key={suggestion.place_id} onClick={() => setAddress(suggestion.description)}>
                  <FaMapMarkerAlt className={styles.suggestionIcon} /> {suggestion.description}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className={styles.checkboxGroup}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showAddressInAd}
                onChange={(e) => setShowAddressInAd(e.target.checked)}
                name="showAddressInAd"
                color="primary"
                disabled={loading}
              />
            }
            label={t('Show address in ad')}
          />
        </div>
        {mapScreenshot && (
          <div className={styles.mapContainer}>
            <img src={`data:image/jpeg;base64,${mapScreenshot}`} alt="Map Screenshot" />
            <div className={styles.screenshotInfoLabel}>
              {t('This screenshot will help the AI to make the ad')}
            </div>
          </div>
        )}
        <div className={styles.formGroup}>
          {renderLabel(t('Rental Use'), false)}
          <select
            id="rental-use"
            value={rentalUse}
            onChange={(e) => setRentalUse(e.target.value)}
            required
            disabled={loading}
          >
            <option value="Wohnung">{t('Apartment')}</option>
            <option value="Einfamilienhaus">{t('Single Family House')}</option>
            <option value="Mehrfamilienhaus">{t('Multi Family House')}</option>
            <option value="Doppelhaus">{t('Duplex')}</option>
            <option value="Laden">{t('Store')}</option>
            <option value="Gewerbefläche">{t('Commercial Space')}</option>
            <option value="Parkplatz">{t('Parking Spot')}</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Ad Language'), false)}
          <select
            id="ad-language"
            value={adLanguage}
            onChange={(e) => setAdLanguage(e.target.value)}
            disabled={loading}
          >
            <option value="en">{t('English')}</option>
            <option value="de">{t('German')}</option>
            <option value="fr">{t('French')}</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          {renderLabel(t('Enter additional information'), false)}
          <input
            type="text"
            id="additional-info"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            placeholder={t('e.g. First occupancy, still being renovated.')}
            disabled={loading}
          />
        </div>
      </div>

      {/* Viewing Appointments Section */}
      <div className={styles.formSection}>
        <h2>
          {t('Viewing Appointments')}
          <Tooltip title={t('Learn more about Viewing Appointments')}>
            <IconButton className={styles.infoButton} onClick={openViewingAppointmentsModal}>
              <FaInfoCircle />
            </IconButton>
          </Tooltip>
        </h2>
        <div className={styles.formGroup}>
          <label htmlFor="appointment-date">{t('Date and Time')}:</label>
          <div className={styles.datePickerContainer}>
            <DatePicker
              selected={appointmentDate}
              onChange={(date) => setAppointmentDate(date)}
              dateFormat="dd/MM/yyyy HH:mm"
              placeholderText={t('Select date and time')}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption={t('Time')}
              disabled={loading}
              className={styles.datePicker}
            />
            {appointmentDate && (
              <button type="button" className={styles.smallButton} onClick={handleAddAppointment} disabled={loading}>
                {t('Add Appointment')}
              </button>
            )}
          </div>
        </div>
        {appointments.length > 0 && (
          <ul className={styles.appointmentList}>
            {appointments.map((appt, index) => (
              <li key={index}>
                <span className={styles.appointmentDetails}>
                  {t('Viewing Appointment')} {index + 1}: {appt}
                </span>
                <IconButton
                  type="button"
                  onClick={() => handleRemoveAppointment(index)}
                  disabled={loading}
                  aria-label={t('Remove appointment')}
                >
                  <CloseIcon color="error" />
                </IconButton>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Generate Ad Button */}
      <button type="submit" className={`${styles.button} ${styles.generateAd}`} disabled={loading}>
        {t('Generate Ad')}
      </button>

      {/* Stepper & Loading Indicator */}
      {loading && (
        <div className={styles.loadingContainer}>
          <Stepper activeStep={generatingAdStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <CircularProgress color="primary" />
            <Typography variant="body1" className={styles.loadingText}>
              {steps[generatingAdStep]}
            </Typography>
          </Box>
        </div>
      )}

      {amenitiesFetched && includeAmenities && renderAmenitiesWithDistance()}

      {/* Generated Ad Display */}
      {generatedAd && (
        <div className={styles.generatedAd}>
          <h2>{t('Generated Ad')}</h2>
          <div ref={adContentRef} className={styles.adContent}>
            <Advertisement adContent={generatedAd} />
          </div>
          <button
            onClick={copyToClipboard}
            className={`${styles.copyButton} ${copied ? styles.copied : ''}`}
          >
            {copied ? <FaCheck /> : <FaRegCopy />} {copied ? t('Copied!') : t('Copy Ad')}
          </button>
        </div>
      )}

      {/* Ad Style Explanations Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel={t('Ad Style Explanations')}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalHeader}>
          <h2>{t('Ad Style Explanations')}</h2>
          <IconButton onClick={closeModal} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.modalContent}>
          <p>
            <strong>{t('Narrative')}:</strong>{' '}
            {t(
              'Narrative style focuses on telling a story about the property, highlighting its unique features and history.'
            )}
          </p>
          <p>
            <strong>{t('Factual')}:</strong>{' '}
            {t(
              'Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.'
            )}
          </p>
          <p>
            <strong>{t('Minimalist')}:</strong>{' '}
            {t(
              'Minimalist style uses concise language and a clean layout to present essential information about the property.'
            )}
          </p>
          <p>
            <strong>{t('Emotional')}:</strong>{' '}
            {t(
              'Emotional style conveys the atmosphere and feeling of the property, highlighting emotions and the lifestyle it enables.'
            )}
          </p>
          <p>
            <strong>{t('Automatic')}:</strong>{' '}
            {t(
              'Automatic style generates a highly appealing and professional real estate advertisement, giving the AI flexibility in presentation.'
            )}
          </p>
        </div>
      </Modal>


      {/* Additional Info Explanations Modal */}
      <Modal
        isOpen={additionalInfoModalIsOpen}
        onRequestClose={closeAdditionalInfoModal}
        contentLabel={t('Additional Info Explanations')}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <button onClick={closeAdditionalInfoModal} className={styles.closeButton}>
          ×
        </button>
        <h2>{t('Additional Info Explanations')}</h2>
        <p>
          {t(
            "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad)."
          )}
        </p>
      </Modal>

      {/* Viewing Appointments Information Modal */}
      <Modal
        isOpen={viewingAppointmentsModalIsOpen}
        onRequestClose={closeViewingAppointmentsModal}
        contentLabel={t('Viewing Appointments Information')}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <button onClick={closeViewingAppointmentsModal} className={styles.closeButton}>
          ×
        </button>
        <h2>{t('Viewing Appointments Information')}</h2>
        <p>
          {t(
            'You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.'
          )}
        </p>
      </Modal>
    </form>
  );
}

export default AdForm;
