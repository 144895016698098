/* Updated SavingCalculator.js */

import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';
import { useTranslation } from 'react-i18next';
import styles from './SavingCalculator.module.css';

const Slider = ({ label, value, onChange, step, min, max, formatValue }) => {
  return (
    <div className={styles.sliderContainer}>
      <label className={styles.sliderLabel}>{label}</label>
      <Range
        step={step}
        min={min}
        max={max}
        values={[value]}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div {...props} className={styles.sliderTrack}>
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className={styles.sliderThumb} />
        )}
      />
      <div className={styles.sliderValue}>
        {formatValue ? formatValue(value) : value}
      </div>
    </div>
  );
};

const calculateMoneySaved = (files, salary, minutes) => {
  // Adjusted to calculate based on monthly salary
  const minutesPerMonth = files * minutes;
  const minutesPerWorkerPerMonth = 45 * 4 * 60; // Approx 4 weeks per month, 45 working hours per week
  const costPerMinute = salary / minutesPerWorkerPerMonth;
  return Math.round(minutesPerMonth * costPerMinute * 12); // Save for 12 months
};

const SavingCalculator = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState(20);
  const [salary, setSalary] = useState(7000); // Default to monthly salary
  const [minutes, setMinutes] = useState(20);
  const [moneySaved, setMoneySaved] = useState(0);

  useEffect(() => {
    setMoneySaved(calculateMoneySaved(files, salary, minutes));
  }, [files, salary, minutes]);

  const formatCurrency = (value) => {
    return value.toLocaleString(undefined, {
      style: 'currency',
      currency: 'CHF',
      maximumFractionDigits: 0,
    });
  };

  return (
    <div className={styles.calculator}>
      <h3>{t('Calculate Your Savings')}</h3>
      <Slider
        label={t('Ads per month')}
        value={files}
        onChange={([value]) => setFiles(value)}
        step={1}
        min={1}
        max={100}
      />
      <Slider
        label={t('Employee Gross Monthly Salary')}
        value={salary}
        onChange={([value]) => setSalary(value)}
        step={1000}
        min={5000}
        max={20000}
        formatValue={formatCurrency}
      />
      <Slider
        label={t('Minutes per ad')}
        value={minutes}
        onChange={([value]) => setMinutes(value)}
        step={5}
        min={10}
        max={60}
      />
      <div className={styles.savingsResult}>
        {t('You can save')} {formatCurrency(moneySaved)} {t('per year')}!
      </div>
    </div>
  );
};

export default SavingCalculator;
